export enum CapacityDataType {
    Total = 'TOTAL',
    Maersk = 'MAERSK',
    Hapaglloyd = 'HAPAGLLOYD',
}

export type CapacityEntityEntry = {
    reeferPlugs: number;
    moves: number;
    yardCapacity: number;
    imoUnits: number;
    oogUnits: number;
    overwrite?: boolean;
    moveCountStretchPercentage: number;
    moveCountStretchNumber: number;
    moveCountStretchNumberOfWeeks: number;
    moveCountCriticalPercentage: number;
    moveCountCriticalNumber: number;
    moveCountCriticalNumberOfWeeks: number;
    capacityDataType: CapacityDataType;
}

export type CapacityEntity = {
    id: string;
    terminalCodeAndStartDate: string;
    terminalCode: string;
    startDate: string;
    date: string;
    entries: CapacityEntityEntry[];
}

export type CapacityEntitiesResponse = {
    capacityEntities: CapacityEntity[];
    capacityTemplateEntities: CapacityEntity[];
}

// @TODO: Change it!
export type OccResponse = any;
