import React, {FunctionComponent} from "react";

export const MaerskCarrierIcon: FunctionComponent<JSX.IntrinsicElements["svg"]> = (
    props
) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <path
                d="M2.41123 0H17.5888C18.91 0 20 1.03206 20 2.28304V17.7171C20 18.968 18.91 20 17.5888 20H2.41123C1.09001 20 0 18.968 0 17.7171V2.28304C0.0330594 1.03206 1.09001 0 2.41123 0Z"
                fill="#42B0D5"/>
            <path
                d="M13.0212 9.72623L16.7625 4.89558V4.87946L11.3619 7.56851L10.0156 1.5625H10L8.65373 7.56851L3.2532 4.87946V4.89558L6.99447 9.72623L1.5625 12.3992V12.4153H7.58934L6.25869 18.4375L10 13.623L13.7256 18.4375H13.757L12.4263 12.4153H18.4218L18.4375 12.3992L13.0212 9.72623Z"
                fill="white"/>
        </svg>

    );
};

export const HapagLloydCarrierIcon: FunctionComponent<JSX.IntrinsicElements["svg"]> = (
    props
) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <path
                d="M5.75251 2.9375H12.107L8.12709 9.4508H9.16388L13.1438 2.9375H19.5318L15.5853 9.4508H20L18.2274 12.3603H13.7793L17.2575 17.0551H10.8696L7.42475 12.3603H6.32107L9.76588 17.0551H3.44482L0 12.3603L5.75251 2.9375Z"
                fill="#033572"/>
        </svg>
    );
};
