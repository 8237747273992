import {DependencyContainer} from "../DependencyContainer";
import {CarrierUpdateDto} from "../../state";
import {CarrierUpdateResponse} from "../clients/CapacityClient";

export default class CapacityService {
    constructor(private readonly factory: DependencyContainer) {}

    async updateCarrier(dto: CarrierUpdateDto): Promise<CarrierUpdateResponse> {
        await this.factory.capacityClient.updateCarrier(dto);
    }

}
