import {terminals} from "./terminals";
import {FunctionComponent} from "react";
import {HapagLloydCarrierIcon, MaerskCarrierIcon} from "../icons";

export type Carrier = {
    id: string;
    name: string;
    image?: FunctionComponent<JSX.IntrinsicElements["svg"]>;
}

const availableCarriers = [
    {
        id: 'MAERSK',
        name: 'Maersk',
        image: MaerskCarrierIcon,
    },
    {
        id: 'HAPAGLLOYD',
        name: 'Hapag Lloyd',
        image: HapagLloydCarrierIcon,
    },
];

export const getCarrierById = (carrierId: string): Carrier => {
    return availableCarriers.find(c => c.id === carrierId) || {
        id: carrierId,
        name: carrierId,
    };
}

export const getCarriersForTerminal = (terminalCode: string): Carrier[] => {
    const foundTerminal = terminals.find(t => t.code === terminalCode);
    if (!foundTerminal) {
        return [];
    }
    const terminalCarriers = foundTerminal.carriers;
    if (!terminalCarriers) {
        return [];
    }
    return terminalCarriers.map(c => getCarrierById(c)) || [];
}

export const isCarriersAvailableForTerminal = (terminalCode: string): boolean =>
  terminals.filter(terminal => terminal.code === terminalCode)?.[0].carriersAvailable;
