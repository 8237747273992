import { useCallback, useEffect, useState } from "react";
import { Maybe } from "../types";

type UseFetchDataValues<T, K> = [
    boolean,
    Maybe<T>,
    Maybe<K>,
    () => Promise<void>,
    boolean
];

export const useFetchData = <T = any, K = any>(
    fetcherFn: () => Promise<T | void>,
    setterFn?: (data: T) => void,
    deps?: any[],
    throttleUntil?: () => boolean
): UseFetchDataValues<T, K> => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<K>();
    const [data, setData] = useState<T>();
    const [complete, setComplete] = useState<boolean>(false);

    const postFetchAction = (response: T | void) => {
        if (!response) {
            return;
        }
        if (setterFn) {
            setterFn(response);
        } else {
            setData(response);
        }
    };

    const fetchAsyncData = useCallback(async () => {
        const isThrottled = throttleUntil?.() || false;
        setComplete(false);
        if (isThrottled) {
            return;
        }
        setLoading(true);
        try {
            const response = await fetcherFn();
            postFetchAction(response);
            setError(undefined);
        } catch (error) {
            setError(error as K);
        } finally {
            setComplete(true);
            setLoading(false);
        }
    }, deps || []);

    useEffect(() => {
        fetchAsyncData();
    }, deps || []);

    return [loading, data, error, fetchAsyncData, complete];
};
