import React, {FunctionComponent, useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {usersAtom} from "../../state";
import {DependencyContainer} from "../../http/DependencyContainer";
import {McButton, McOption, McSelect, McTable} from "@maersk-global/mds-react-wrapper";
import {TableColumn} from "@maersk-global/mds-components-core/mc-table/types";
import {useNavigate, useParams} from "react-router-dom";
import {AppRoutes} from "../../config/routes";
import {useRedirectToRoute} from "../common/hooks/useRedirectToRoute";
import {useIntl} from "react-intl";
import {terminals} from "../common/helpers/terminals";
import useAuth from "../common/hooks/useAuth";
import AddUserDrawer from "./AddUserDrawer/AddUserDrawer";
import EditUserDrawer from "./EditUserDrawer/EditUserDrawer";

const {usersService} = new DependencyContainer();

const UsersContainer: FunctionComponent = () => {
    const {userData} = useAuth();
    const {formatMessage} = useIntl();
    const {id} = useParams<{ id: string }>();
    const [selectedTerminal, setSelectedTerminal] = useState(id || "");
    const [addingUser, setAddingUser] = useState(false);
    const [editUser, setEditUser] = useState(null)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [users, setUsers] = useRecoilState(usersAtom);
    const redirectToRoute = useRedirectToRoute();
    const navigate = useNavigate();

    const handleSelectChange = async (selectedCode) => {
        if (!selectedCode) return
        setSelectedTerminal(selectedCode);
        setLoading(true);
        // @ts-ignore
        setError(null);

        try {
            navigate(`/users/${selectedCode}`);
            await redirectToRoute(AppRoutes.Users, {id: selectedCode});
            // fetchUserData(selectedCode);
        } catch (err) {
            // setError(formatMessage({id: "genericErrorMessage"}));
            setLoading(false);
        }
    };


    const fetchUserData = async (terminalCode: string) => {
        setLoading(true);
        // @ts-ignore
        setError(null);

        try {
            const response = await usersService.getUsers(terminalCode);
            setUsers(response);
        } catch (err) {
            console.error("Error fetching capacity data:", err);
            // @ts-ignore
            setError(formatMessage({id: "genericErrorMessage"}));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedTerminal) {
            fetchUserData(selectedTerminal);
        }
    }, [selectedTerminal]);

    const columns: Array<TableColumn> = [
        {
            id: "name",
            label: "Name",
            width: "400px",
        },
        {
            id: "email",
            label: "Email",
            tabularFigures: true,
            width: "320px",
        }
    ];

    // @ts-ignore
    return (
        <div className="UsersContainer">
            <AddUserDrawer
                selectedTerminal={selectedTerminal}
                open={Boolean(addingUser)}
                fetchRules={() => fetchUserData(selectedTerminal)}
                setOpen={() => setAddingUser(false)}
            />

            <EditUserDrawer open={Boolean(editUser)} setOpen={() => setEditUser(null)}
                            fetchRules={() => fetchUserData(selectedTerminal)} selectedTerminal={selectedTerminal}
                            selectedUser={editUser}/>


            <McSelect
                label={"Select terminal"}
                placeholder="Select terminal"
                value={selectedTerminal}
                optionselected={(e) => {
                    // @ts-ignore
                    handleSelectChange(e.target.value)
                }}
                name="terminal">
                {userData?.terminalCodes && terminals.filter(terminal => userData.terminalCodes.includes(terminal.code)).map((terminal) => (
                    <McOption key={terminal.code} value={terminal.code}>
                        {terminal.name}
                    </McOption>
                ))}
            </McSelect>
            <h2 className="container-title">
                Users Management
                <McButton fit="small" variant="outlined" appearance="secondary"
                          click={() => setAddingUser(true)}>
                    Add user
                </McButton>
            </h2>

            {/*//@ts-ignore*/}
            <McTable expand columns={columns} data={users} rowclick={(e) => setEditUser(e.detail.rowData)}/>
        </div>
    )
};

export default UsersContainer;
