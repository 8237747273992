import {TableColumn} from "@maersk-global/mds-components-core/mc-table/types";
import {SquareIcon, SquareIconTypes} from "../../common/icons";
import {CSSProperties} from "react";
import FormattedMessage from "../../common/FormattedMessage";

export type CustomTableColumnHeader = {
    id: TableColumn['id'];
    render: () => any;
}

const commonHeaderStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
}

const useCarrierColumnsHeader = () => {
    const customTableColumnHeaders: CustomTableColumnHeader[] = [
        {
            id: 'moveCountStretch',
            render: () => {
                return (
                    <div style={commonHeaderStyles}>
                        <SquareIcon type={SquareIconTypes.Warning} />
                        <FormattedMessage id="threshold1" />
                    </div>
                );
            },
        },
        {
            id: 'moveCountCritical',
            render: () => {
                return (
                    <div style={commonHeaderStyles}>
                        <SquareIcon type={SquareIconTypes.Error} />
                        <FormattedMessage id="threshold2" />
                    </div>
                );
            },
        },
    ];

    const getCustomHeaders = () => customTableColumnHeaders;

    return { getCustomHeaders };
}

export default useCarrierColumnsHeader;