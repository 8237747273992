enum Carriers {
  Maersk = 'MAERSK',
  Hapaglloyd = 'HAPAGLLOYD',
}

export const terminals = [
  { name: 'Algeciras', code: 'ESALR', carriers: [Carriers.Maersk, Carriers.Hapaglloyd], carriersAvailable: false },
  { name: 'Rotterdam  Maasvlakte II', code: 'NLROT', carriers: [Carriers.Maersk, Carriers.Hapaglloyd], carriersAvailable: false },
  { name: 'Tanjung Pelepas', code: 'MYTPP', carriers: [Carriers.Maersk, Carriers.Hapaglloyd], carriersAvailable: false },
  { name: 'Suez Canal Container Terminal', code: 'EGPSD', carriers: [Carriers.Maersk, Carriers.Hapaglloyd], carriersAvailable: false },
  { name: 'Salalah', code: 'OMSLV', carriers: [Carriers.Maersk, Carriers.Hapaglloyd], carriersAvailable: false },
  { name: 'Tangier Med - TC1', code: 'MAPTMTM', carriers: [Carriers.Maersk, Carriers.Hapaglloyd], carriersAvailable: false },
  { name: 'Tangier Med - TM2', code: 'MAPTM02', carriers: [Carriers.Maersk, Carriers.Hapaglloyd], carriersAvailable: false },
];