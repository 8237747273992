import {HttpClient} from "../HttpClient";
import {CapacityUrls} from "../urls";
import {CarrierUpdateDto} from "../../state";
import {AxiosResponse} from "axios";

// @TODO: Replace with proper type
export type CarrierUpdateResponse = any;

const USE_MOCK = true;

export default class CapacityClient extends HttpClient {

  async updateCarrier(dto: CarrierUpdateDto): Promise<AxiosResponse<CarrierUpdateResponse>> {
    const url = CapacityUrls.getUrlForUpdateCarrier();
    if (USE_MOCK) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({} as CarrierUpdateResponse);
        }, 300);
      });
    }
    return await this.post<CarrierUpdateResponse>(url, dto);
  }

}
