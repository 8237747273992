import React, {FunctionComponent} from "react";

export enum SquareIconTypes {
    Warning = 'warning',
    Error = 'error',
}

type SquareIconProps = JSX.IntrinsicElements["svg"] & {
    type: SquareIconTypes;
}

const mapTypeToColor: Record<SquareIconTypes, string> = {
    [SquareIconTypes.Warning]: 'rgba(255, 208, 41, 1)',
    [SquareIconTypes.Error]: 'rgba(255, 96, 0, 1)',
}

export const SquareIcon: FunctionComponent<SquareIconProps> = ({ type, ...props}) => {
    const color = mapTypeToColor[type];
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
            <rect width="12" height="12" fill={color}/>
        </svg>

    );
};
