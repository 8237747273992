import { httpClient } from '../http-client';
import { ResetCapacityRequest, ResetCapacityResponse } from './types';

// New Carrier Service
// TODO: move the rest of the functions to here along the way
export const carrierService = () => {
  const client = httpClient();
  const BASE_URL = '/v1/capacity';

  return {
    resetCapacity: async ({ rowId, type }: ResetCapacityRequest): Promise<ResetCapacityResponse | null> => {
      const url = `${BASE_URL}/${rowId}/${type}`;
      try {
        const result = await client.delete<ResetCapacityResponse>(url);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
  };
};

const logger = (message: string): void =>
  console.error(`[Carrier Service] ${message}`);
