import React from "react";
import ReactDOM from "react-dom/client";
import "./root.scss";
import App from "./App";
import { MsalProvider } from "@azure/msal-react";
import getPublicClientApplication from "./http/publicClientApplicationProvider";
import { SnackbarProvider } from "notistack";

const publicClientApplication = getPublicClientApplication();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={publicClientApplication}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <App />
      </SnackbarProvider>
    </MsalProvider>
  </React.StrictMode>
);
