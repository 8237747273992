import {useFetchData} from "../../common/hooks/useFetchData";
import {useRecoilState, useRecoilValue} from "recoil";
import {capacityEntitiesAtom, capacityTemplatesAtom, occAtom, terminalCodeAtom} from "../../../state";
import {DependencyContainer} from "../../../http/DependencyContainer";
import {CapacityEntitiesResponse, OccResponse} from "../types";
import {useEffect} from "react";

const { octwService } = new DependencyContainer();

const useCapacityData = () => {
    const terminalCode = useRecoilValue(terminalCodeAtom);
    const [capacityEntities, setCapacityEntities] = useRecoilState(capacityEntitiesAtom);
    const [capacityTemplates, setCapacityTemplates] = useRecoilState(capacityTemplatesAtom);
    const [occ, setOcc] = useRecoilState(occAtom);

    const [loadingCapacity,,, capacityFetcher] = useFetchData<CapacityEntitiesResponse, any>(
        async () => {
            if (!terminalCode) {
                return;
            }
            return octwService.getCapacityForTerminal(terminalCode);
        },
        (response: CapacityEntitiesResponse) => {
            if (response.capacityEntities) {
                setCapacityEntities(response.capacityEntities);
            }
            if (response.capacityTemplateEntities) {
                setCapacityTemplates(response.capacityTemplateEntities);
            }
        },
        [terminalCode],
        () => !terminalCode
    );

    const [loadingOcc,,, occFetcher] = useFetchData<any, any>(
        async () => {
            if (!terminalCode) {
                return;
            }
            return octwService.getOccForTerminal(terminalCode);
        },
        (response: OccResponse) => {
            setOcc(response)
        },
        [terminalCode],
        () => !terminalCode
    );

    useEffect(() => {
        capacityFetcher();
    }, [terminalCode]);

    return { loadingCapacity, loadingOcc, capacityTemplates, capacityEntities, occ, capacityFetcher, occFetcher, setCapacityEntities };
}

export default useCapacityData;
